





































import { User, Global, Reports, Projects } from '@/store';
import Vue from 'vue';
import Component from 'vue-class-component';
import DevicesListTable from '@/components/DevicesListTable.vue';
import API, { Types } from '@/modules/API';
import MessageDialog from '@/components/dialogs/MessageDialog.vue';
import LoadingDialog from '@/components/dialogs/LoadingDialog.vue';
import moment from 'moment-timezone';
import Utils from '@/modules/Utils';

@Component({
    components: { 
        DevicesListTable, 
        MessageDialog,
        LoadingDialog
    }
})

export default class ChangeDeviceStatus extends Vue {
    @User.State('project') project;
    @Global.State('readonly_user') readonly_user;
    @Global.State('lang') lang;
    @Global.Mutation('setPageTitle') setPageTitle;
    @Reports.Getter('commissioned_map') commissioned_map;
    @Projects.State('projects_metadata') projects_metadata;

    selectedDevices = [];
    mode = 1;
    isLoadingDialogOpen = false;
    isMessageDialogOpen = false;
    message = 'You must choose at least one item in table';

    mounted(){
        this.setPageTitle('Device Status');
    }

    get deviceModes(){
        return [
            { text: this.$t('Connected'), value: 1},
            { text: this.$t('Fault In Care'), value: -9}, 
            { text: this.$t('Maintenance'), value: -1}
        ];
    }

    updateSelected(selected){
        this.selectedDevices = [...selected];
    }

    async handleChangeClicked(){
        if (!this.selectedDevices.length) {
            this.isMessageDialogOpen = true;
            return;
        }

        this.isLoadingDialogOpen = true;
        let isCompleted = true;

        const slices = Math.ceil(this.selectedDevices.length / 100);
        let startIndex = 0, endIndex = Math.min(this.selectedDevices.length, 100);
        for (let i = 0; i < slices; i++){
            const current = this.selectedDevices.slice(startIndex, endIndex);
            await Promise.all(current.map( async (device) => {
                try {
                    const response = await this.updateDeviceMode(device.id, this.mode);
                    if (response.ok) {
                        const device_on_store = this.commissioned_map.get(device.id);
                        if (device_on_store) {
                            this.updateDeviceModeOnStore(device_on_store);
                        }
                    }
                }catch (e){
                    isCompleted = false;
                }
            }));
            startIndex = endIndex;
            endIndex = Math.min(this.selectedDevices.length, endIndex + 100);
        }

        this.updateSelected([]);

        this.isLoadingDialogOpen = false;
        this.notifyStatus(isCompleted);
    }     

    updateDeviceMode(device_id, mode){
        return API.post('', `${Types.PROJECTS}/${this.project.id}/${Types.DEVICES}/${device_id}/streams/sys___active/evt/`, { content: mode }, {}, 'v4');
    }

    updateDeviceModeOnStore(device){
        const prev_status = Utils.getDeviceStatusByPieChart(device);

        device['sys___active'] = this.mode;
        let last_change = moment().utcOffset(0).valueOf(); // milliseconds
        last_change = Math.floor(last_change / 1000); // seconds
        device['sys___active__modified'] = last_change;

        const new_status = Utils.getDeviceStatusByPieChart(device);
        const activity_chart = this.projects_metadata.get(this.project.id).activity;
        const prev_status_count = activity_chart.find((status) => status.place === prev_status);
        prev_status_count.value -= 1;
        const new_status_count = activity_chart.find((status) => status.place === new_status);
        new_status_count.value += 1;
    }

    notifyStatus(isCompleted){
        const notifyData = isCompleted 
            ? {
                text: 'Devices mode updated successfully!',
                type: 'success',
                title: 'Success!'
            } : {
                text: 'Some mode devices could not update. Please try again later.',
                type: 'error',
                title: 'Error!'
            };
        
        this.$notify(notifyData);
    }
}

